import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Mycontext } from '../../contexts/Context_1';
import { FaPlus, FaMinus } from "react-icons/fa6";




function Cprod({ src, title, price, kdv, total, amount, i}) {
  const { t } = useTranslation("translate");
  const { handlePrices } = useContext(Mycontext);


  return (
    <>
      <div className={`cart ${amount > 0 ? "active": ""}`}>
        <div className="cart_left">
          <div className="img_cont"><img src={src} alt={`prod-${i}`} /></div>
          <p className="cart_name">{t(`Product.${title}.title`)}</p>
        </div>
      
        <div className="cart_right">
          <div className="i_cont">
            <FaPlus onClick={()=> handlePrices(title, 1)}/>
            {amount}
            <FaMinus onClick={()=> handlePrices(title, -1)}/>
          </div>
          <p readOnly>{(price*amount).toFixed(2)}$</p>
          <p>{(kdv*amount).toFixed(2)}$</p>
          <p>{(total*amount).toFixed(2)}$</p>
          {/* <box-icon name='x'></box-icon> */}
        </div>
      </div>
    </>
  )
}

export default Cprod