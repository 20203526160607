import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img_2 from "../../img/Slider/Img_2.png";
import img_1 from "../../img/Slider/Img_1.png";
import img_3 from "../../img/Slider/Img_3.png";
import "./Carousel.css";
import { useTranslation } from "react-i18next";

const Carousel = () => {
  const { t } = useTranslation("translate");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div id="Carousel">
      <Slider {...settings}>
        <div>
          <p>{t("cart-1.h2")}</p>
          <img src={img_1} alt="Slide 1" />
        </div>

        <div>
          <p>{t("cart-2.h2")}</p>
          <img src={img_2} alt="Slide 1" />
        </div>

        <div>
          <p>{t("cart-3.h2")}</p>
          <img src={img_3} alt="Slide 1" />
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
