import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Contexts
import { MyProvider } from "./contexts/Context_1";
import { PopContext } from "./contexts/PopContext";
// Pages
import Home from "./components/Home";
import Cart from "./components/cart/Cart";
import Products from "./components/products/Products";
import Privacy from "./components/privacy/Privacy";
// Tost
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import ContactUs from "./components/contacUs/ContactUs";

function App() {
  return (
    <PopContext>
      <MyProvider>
        <Toaster position="top-right" reverseOrder={false} />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/Cart" element={<Cart />} />
            <Route path="/samba-garson-privacy-policy" element={<Privacy />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/privacy.html" element={<Privacy />} />
            <Route path="/iletisim" element={<ContactUs />} />
          </Routes>
        </Router>
      </MyProvider>
    </PopContext>
  );
}

export default App;
