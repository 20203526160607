import React from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import Promo from './promo/Promo';
import Customers from './customers/Customers';
import About from './about/About';
import Product from './product/Product';
import Contact from './contact/Contact';
import Landing from './landing/Landing';
import Loader from './loader/Loader';
import Popup from './popup/Popup';

function Home() {
  localStorage.removeItem("prices")
  console.log('removed');
  return (
    <>
      <Popup/>
      <Loader/>
      <Header/>
      <Promo/>
      <Landing/>
      <Customers/>
      <About/>
      <Product/>
      <Contact/>
      <Footer/>
    </>
  )
}

export default Home;