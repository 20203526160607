import React, { createContext, useContext, useState } from 'react';
import prod_1 from "../img/Products/prod-1.png" //Getir Yemek 
import prod_2 from "../img/Products/prod-2.png" //Yemek Sepeti
import prod_3 from "../img/Products/prod-9.png" //TRENDYOL YEMEK
import prod_4 from "../img/Products/prod-5.png" //SAMBA PATRON
import prod_5 from "../img/Products/prod-6.png" //SAMBA GARSON
import prod_6 from "../img/Products/prod-7.png" //MOBİLE CALLER ID
import prod_7 from "../img/Products/prod-8.png" //VERİTABANI
import prod_8 from "../img/Products/prod-4.png" //VOIP CALLER
import prod_9 from "../img/Products/prod-3.png" //Akıllı Garson
import prod_10 from "../img/Products/prod-10.png" //E-Adisyon
import prod_11 from "../img/Products/prod-11.png" //Pentegrasyon

import axios from 'axios';
import { PopUp } from './PopContext';




const currency = process.env.REACT_APP_CURRENCY_API;
const Mycontext = createContext();

const MyProvider = ({ children }) => {
  const [promoStat, setPromoStat] = useState(false);
  const [contactStat, setContactStat] = useState(false);
  const [prices, setPrices] = useState([]);
  const [TRY, setTRY] = useState()
  const [ytLink, setYtLink] = useState(null);
  const { handlepopUp } = useContext(PopUp);
  
  let tempPrices = [
    {
      title: "prod-1",
      src: prod_1,
      price: 109, // Updated price
      kdv: 21.8, // Updated KDV (20 percent of the updated price)
      total: 109 + 21.8, // Updated total
      amount: 0
    },
  
    {
      title: "prod-2",
      src: prod_2,
      price: 109,
      kdv: 21.8,
      total: 109 + 21.8,
      amount: 0
    },
  
    {
      title: "prod-3",
      src: prod_3,
      price: 109,
      kdv: 21.8,
      total: 109 + 21.8,
      amount: 0
    },
  
    {
      title: "prod-4",
      src: prod_4,
      price: 109,
      kdv: 21.8,
      total: 109 + 21.8,
      amount: 0
    },
  
    {
      title: "prod-5",
      src: prod_5,
      price: 59, // Updated price
      kdv: 11.8, // Updated KDV (20 percent of the updated price)
      total: 59 + 11.8, // Updated total
      amount: 0
    },
  
    {
      title: "prod-6",
      src: prod_6,
      price: 0, // Updated price
      kdv: 0, // Updated KDV (20 percent of the updated price)
      total: 0 + 0, // Updated total
      amount: 0
    },
  
    {
      title: "prod-7",
      src: prod_7,
      price: 360, // Updated price
      kdv: 72, // Updated KDV (20 percent of the updated price)
      total: 360 + 72, // Updated total
      amount: 0
    },
  
    {
      title: "prod-8",
      src: prod_8,
      price: 100, // Updated price
      kdv: 20, // Updated KDV (20 percent of the updated price)
      total: 100 + 20, // Updated total
      amount: 0
    },
  
    {
      title: "prod-9",
      src: prod_9,
      price: 59,
      kdv: 11.8,
      total: 59 + 11.8,
      amount: 0
    },
    {
      title: "prod-10",
      src: prod_10,
      price: 129, // Updated price
      kdv: 25.8, // Updated KDV (20 percent of the updated price)
      total: 129 + 25.8, // Updated total
      amount: 0
    },
    {
      title: "prod-11",
      src: prod_11,
      price: 159, // Updated price
      kdv: 31.8, // Updated KDV (20 percent of the updated price)
      total: 159 + 31.8, // Updated total
      amount: 0
    },
  ];
  

  const handlePrices = (title, sign) => {
    //console.log(title, sign)
    let localPrice = JSON.parse(localStorage.getItem("prices"));
    if (localPrice !== null) {
      setPrices(localPrice);
      tempPrices = localPrice;
    } else {
      localStorage.setItem("prices", JSON.stringify(tempPrices));
      setPrices(JSON.parse(localStorage.getItem("prices")));
    }
    tempPrices.forEach((item)=> {
      if(item.title === title) {
        item.amount += sign;
        localStorage.setItem("prices", JSON.stringify(tempPrices));
        setPrices(JSON.parse(localStorage.getItem("prices")));
      }
    })
    if (sign !== -1) {
      handlepopUp("added");
    } else if (sign === -1) {
      handlepopUp("removed");
    }
    //console.log("metual");
    //localStorage.removeItem("prices")
    //console.log(localStorage.getItem(prices))
  }
  const handlePromo = (src) => {
    setPromoStat(!promoStat)
    setYtLink(src);
  }
  const handleContactStat = () => {
    setContactStat(!contactStat);
    //console.log("Active");
  }

  /* Currency */
  const getExchangeRate = async () => {
    try {
      const response = await axios.get(currency);
      //console.log("response",response);
      const exchangeRate = response.data.rates.TRY;
      return exchangeRate;
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
    }
  };
  getExchangeRate().then(rate => {
    //console.log('USD to TRY Rate:', rate);
    setTRY(rate);
  })


  return (
    <Mycontext.Provider value={{ 
      promoStat, handlePromo, 
      contactStat, handleContactStat, 
      prices, handlePrices, setPrices, 
      TRY,
      ytLink,
      tempPrices,
      }}>
      {children}
    </Mycontext.Provider>
  );
};
export {MyProvider, Mycontext};