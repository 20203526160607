import { useTranslation } from "react-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { Mycontext } from "../../contexts/Context_1";
import Carousel from "../carousel/Carousel";
import "./landing.css";
import "../../index.css";

// Icons
import { FaFacebookF } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
//import { SiWhatsapp } from "react-icons/si";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";

function Landing() {
  const { t } = useTranslation("translate");
  const { handlePromo } = useContext(Mycontext);
  const src_0 = "https://www.youtube.com/embed/nz0erc6m5Wo?si=-UtENUGW1ITT5XMH";
  const homeRef = useRef(null);
  const [cName, setcName] = useState(null);

  useEffect(() => {
    const calcOff = () => {
      const top = window.scrollY;
      const offset = homeRef.current.offsetTop;
      const height = homeRef.current.offsetHeight;
      if (top >= offset && top < offset + height) {
        setcName("start_animate");
      } else {
        //setcName(null);
      }
    };
    window.addEventListener("scroll", calcOff);
    calcOff();
    return () => {
      window.removeEventListener("scroll", calcOff);
    };
  }, [homeRef]);

  //const i1 = {'--i': 1};
  //const i2 = {'--i': 2};
  const i3 = { "--i": 3 };
  //const i4 = {'--i': 4};
  //const i5 = {'--i': 5};
  //const i6 = {'--i': 6};

  return (
    <section id="home" ref={homeRef} className={cName && `${cName}`}>
      <div className="home-left">
        <h1>LiwaPOS</h1>
        {/* <h3>{t("hero.t1")}</h3> */}
        <h3>{t("hero.t2")}</h3>
        <div className="text-animate">
          <h2>{t("hero.t3")}</h2>
        </div>
        <p>{t("hero.p")}</p>

        <div className="btn-cont">
          <a href="/products" className="btn">
            {t("land.btn_1")}
          </a>
          <Link className="btn" onClick={() => handlePromo(src_0)}>
            {t("land.btn_2")}
          </Link>
        </div>

        <div className="social">
          <a href="https://www.facebook.com/liwasoft/" className="fb">
            <FaFacebookF />
          </a>
          <a href="https://www.instagram.com/liwasoft/" className="ins">
            <FaInstagram />
          </a>
          <a
            href="https://www.youtube.com/channel/UCk9flKQF502tzxmDo_4VPbw"
            className="yt"
          >
            <IoLogoYoutube />
          </a>
          <a href="https://wa.me/908508407807" className="wa">
            <IoLogoWhatsapp />
          </a>
        </div>

        <div className="home-right">
          <div>
            <Carousel />
          </div>
        </div>
      </div>
      <span className="animate" style={i3}></span>
    </section>
  );
}

export default Landing;
