import Contact from "../contact/Contact";
import Footer from "./Footer";

const ContactUs = () => {
  return (
    <section>
      <Contact className="start_animate" />
      <Footer />
    </section>
  );
};

export default ContactUs;
