import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import prod_1 from "../../img/iMac_1.png"
import prod_2 from "../../img/iMac_2.png"
import prod_3 from "../../img/Products/prod-3.png"
import './product.css'


function Product() {
  const { t } = useTranslation("translate");
  const [cName, setcName] = useState(null);
  const prodRef = useRef(null);

  
  useEffect(() => {
    const calcOff = () => {
      const top = window.scrollY + 900;
      const offset = prodRef.current.offsetTop;
      const height = prodRef.current.offsetHeight;
      if (top >= offset && top < (offset+height)) {
        setcName("start_animate")
      } else {
        //setcName(null);
      }
      //console.log("top",top, "offset",offset, "height", height)
    };
    window.addEventListener('scroll', calcOff);
    calcOff();
    return () => {
    window.removeEventListener('scroll', calcOff);
    };
  }, [prodRef]); 
  const i1 = {'--i': 1};
  const i2 = {'--i': 1.5};
  const i3 = {'--i': 1.8};
  const i4 = {'--i': 2};
  //const i5 = {'--i': 5};
  //const i6 = {'--i': 6};

  return (
    <section id='product' ref={prodRef} className={cName && `${cName}`}>
        <div className="container">
          <div className="title">
            <h1>{t("product.title")}<span></span></h1>
            <span className="animate" style={i1}></span>
          </div>

          <div className="cards-cont">

            <div>
              <div className="prod-card">
                <img src={prod_1} alt="imac_1" />
                <h3>{t("product.card-1.h3")}</h3>
              </div>
              <span className="animate" style={i2}></span>
            </div>

            <div>
              <div className="prod-card">
                <img src={prod_3} alt="imac_1" />
                <h3>{t("product.card-2.h3")}</h3>
              </div>
              <span className="animate" style={i3}></span>
            </div>

            <div>
              <div className="prod-card">
                <img src={prod_2} alt="imac_1" />
                <h3>{t("product.card-3.h3")}</h3>
              </div>
              <span className="animate" style={i4}></span>
            </div>

          </div>

          <div className="rect">
            <div className="txts">
              <h2>{t("product.txt.h2")}</h2>
              <h3>{t("product.txt.h3")} !</h3>
            </div>
            <div className="btn-cont">
              <a href='/products' className="btn">{t("product.txt.a")}</a>
            </div>
            <span className="animate" style={i3}></span>
          </div>

        </div>
      </section>

  )
}

export default Product