import { useContext } from 'react';
import { Mycontext } from '../../contexts/Context_1';
import './promo.css'


function Promo({src}) {
  const { promoStat, handlePromo, ytLink} = useContext(Mycontext);
  return (
    <div id='Promo_cont' className={promoStat? "active": ""}>
        <div className="container">
          <div className="closeyt" onClick={handlePromo}><i className='bx bx-x'></i></div>
          <iframe src={ytLink} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen></iframe>
        </div>
    </div>
  )
}

export default Promo;