import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SliderFunc from "../slider/Slider";
import energy from "../../img/energy.png";
import easy from "../../img/easy.png";
import group from "../../img/group.png";
import "./customers.css";

function Customers() {
  const { t } = useTranslation("translate");
  const customerRef = useRef(null);
  const [cName, setcName] = useState(null);

  useEffect(() => {
    const calcOff = () => {
      const top = window.scrollY + 850;
      const offset = customerRef.current.offsetTop;
      const height = customerRef.current.offsetHeight;
      if (top >= offset && top < offset + height) {
        setcName("start_animate");
      } else {
      }
    };
    window.addEventListener("scroll", calcOff);
    calcOff();
    return () => {
      window.removeEventListener("scroll", calcOff);
    };
  }, [customerRef]);
  const i1 = { "--i": 1 };
  const i2 = { "--i": 1 };
  const i3 = { "--i": 1.5 };
  const i4 = { "--i": 1.5 };
  const i5 = { "--i": 1.8 };
  //const i6 = {'--i': 6};

  return (
    <section id="customers" ref={customerRef} className={cName && `${cName}`}>
      <div className="customer-container">
        <div className="cards-cont">
          <div className="ccart">
            <div className="icon">
              <img src={energy} alt="energy" />
            </div>
            <h2>{t("cart-1.h2")}</h2>
            <p>{t("cart-1.p")}</p>
            <span className="animate" style={i1}></span>
          </div>

          <div className="ccart">
            <div className="icon">
              <img src={group} alt="easy" />
            </div>
            <h2>{t("cart-2.h2")}</h2>
            <p>
              {t("cart-2.p").length > 160
                ? `${t("cart-2.p").slice(0, 160)}...`
                : t("cart-2.p")}
            </p>
            <span className="animate" style={i2}></span>
          </div>

          <div className="ccart">
            <div className="icon">
              <img src={easy} alt="group" />
            </div>
            <h2>{t("cart-3.h2")}</h2>

            <p>
              {t("cart-3.p").length > 300
                ? `${t("cart-3.p").slice(0, 165)}...`
                : t("cart-3.p")}
            </p>
            <span className="animate" style={i3}></span>
          </div>
        </div>

        <div className="title">
          <h1>
            {t("title.h1")}
            <span></span>
          </h1>
          <span className="animate" style={i4}></span>
        </div>
        <div className="subtitle">
          <h3>{t("title.h2")}</h3>
          <h2>{t("title.h3")}</h2>
          <span className="animate" style={i5}></span>
        </div>
        <div className="slider-div">
          <SliderFunc />
        </div>
      </div>
    </section>
  );
}

export default Customers;
