import React, { useState } from 'react'
import { createContext } from 'react'
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';

const PopUp = createContext();

function PopContext({children}) {
  //const [ispopup, setIspopup] = useState(false);
  const { t } = useTranslation("translate");
  const [isDekont, setIsDekont] = useState(false);

  const handlepopUp = (message) => {
    //setIspopup(true);
    //setMessage(message);
    //setTimeout(() => {
    //  setIspopup(false);
    //}, 3000);
    if (message === "red") {
      toast.error(t(`popup.${message}`))
    } else if (message === "added" || message === "removed") {
      toast.success(t(`popup.${message}`), {
        autoClose: 1000,
      });
    } else {
      toast.success(t(`popup.${message}`))
    }
  }
  return (
    <PopUp.Provider value={{
      handlepopUp,
      isDekont,
      setIsDekont
     }}>
      {children}
    </PopUp.Provider>
  )
}

export { PopContext, PopUp }