// LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './lang.css'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation("translate");
  const selectedLanguage = localStorage.getItem("Local_Language");

  const handleLanguage = (lang) => {
    localStorage.setItem("Local_Language", lang);
    i18n.changeLanguage(localStorage.getItem("Local_Language"));
  };

  return (
    <div>
      <select id="languageSelect" onChange={(e) => handleLanguage(e.target.value)} value={selectedLanguage}>
        <option value="tr">Türkçe</option>
        <option value="en">English</option>
        <option value="fr">France</option>
        <option value="dt">German</option>
        <option value="arab">Arabic</option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;