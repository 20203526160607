import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import aboutimg from "../../img/Slider/Img_3.png"
import './about.css'


function About() {
  const { t } = useTranslation("translate");
  const [cName, setcName] = useState(null);
  const aboutRef = useRef(null);

  
  useEffect(() => {
    const calcOff = () => {
      const top = window.scrollY + 900;
      const offset = aboutRef.current.offsetTop;
      const height = aboutRef.current.offsetHeight;
      if (top >= offset && top < (offset+height)) {
        setcName("start_animate")
      } else {
      }
    };
    window.addEventListener('scroll', calcOff);
    calcOff();
    return () => {
    window.removeEventListener('scroll', calcOff);
    };
  }, [aboutRef]); 
  const i1 = {'--i': 1};
  const i2 = {'--i': 1.3};
  const i3 = {'--i': 1.6};
  
  return (
    <section id='about' ref={aboutRef} className={cName && `${cName}`}>
        <div className="container">
          <div className="title">
            <h1>{t("about.title")}<span></span></h1>
            <span className="animate" style={i1}></span>
          </div>

          <div className="contents">
            <div className="content-left">
              {t("about.p").split('<br>').map((p, i) => (
                <React.Fragment key={i}>
                  {i > 0 && <><br/> <br/></>} 
                  {p}
                </React.Fragment>
              ))}
              <span className="animate" style={i2}></span>
            </div>

            <div className="content-right">
              <img src={aboutimg} alt="aboutimg" />
              <span className="animate" style={i3}></span>
            </div>
          </div>

        </div>
      </section>
  )
}

export default About