import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ContactForm from "../form/ContactForm";
import contactimg from "../../img/Slider/Img_1.png";
import "./contact.css";

function Contact({ className }) {
  const { t } = useTranslation("translate");
  const contactRef = useRef(null);
  const [cName, setcName] = useState(null);

  useEffect(() => {
    const calcOff = () => {
      const top = window.scrollY + 900;
      const offset = contactRef.current.offsetTop;
      const height = contactRef.current.offsetHeight;
      if (top >= offset && top < offset + height) {
        setcName("start_animate");
      }
    };
    window.addEventListener("scroll", calcOff);
    calcOff();
    return () => {
      window.removeEventListener("scroll", calcOff);
    };
  }, [contactRef]);

  const i1 = { "--i": 1 };
  const i2 = { "--i": 2 };
  //const i3 = {'--i': 3};
  //const i4 = {'--i': 4};
  //const i5 = {'--i': 5};
  //const i6 = {'--i': 6};

  return (
    <section id="contact" ref={contactRef} className={`${cName} ${className}`}>
      <div className="contact-cont">
        <div className="title">
          <h1>
            {t("contact.title.h1")}
            <span></span>
          </h1>
          <span className="animate" style={i1}></span>
        </div>
        <div className="contents">
          <div className="content-left">
            <ContactForm className={"isContact"} />
            <div className="content-right">
              <div>
                <img src={contactimg} alt="contactimg" />
                <span className="animate" style={i2}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
