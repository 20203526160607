import React from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import './privacy.css'

function Privacy() {
  return (
    <div className='Privacy'>
      <Header/>
      <div className="container_p">
        <h1 className='priv'>Liwa Yazılım Gizlilik ve Çerez Politikası</h1>
        <p>İşbu Gizlilik ve Çerez Politikası ile LİWA YAZILIM San. Tic. Ltd. Şti.'ne ("LİWA YAZILIM") aktarılan kişisel verilerin korunması konusundaki temel bilgilere yer verileceği gibi, Liwa Yazılıma ait www.liwasoft.com web sitesi ziyaretçilerine, çerez politikası ve politikanın nasıl yönetilebileceği konularında bilgilendirme yapılacaktır. Web sitesinde yer alan çerez kullanım uyarısının kapatılması ya da web sitesi kullanımına devam edilmesi halinde çerezlere onay verildiği kabul edilir. Çerez kullanımını onaylamıyorsanız web sitesine devam etmemenizi ya da tarayıcınızdan çerez tercihlerinizi değiştirmenizi rica ederiz. Çerezlere izin verilmemesi halinde web sitesinin bazı özelliklerinin işlevselliğini yitirebileceğini hatırlatmak isteriz. LİWASOFT Yazılım, 6698 sayılı Kişisel Verilerin Korunması Kanunu ("6698 sayılı Kanun") m. 10'da belirtilen aydınlatma yükümlülüğünü yerine getirmek amacıyla aşağıdaki sunulan açıklamaları www.liwasoft.com web-sitemizi ve/veya mobil uygulamalarımızı kullanan 3. kişilerin dikkatine sunar.</p>

        <h4>1- Liwa Yazılımın kişisel verileri toplamasının yasal dayanağı nedir?</h4>
        <p>Müşterilerimizin kişisel verilerinin korunması konusunda en temel düzenleme 6698 sayılı
        Kişisel Verilerin Korunması Kanununda yapılmıştır. Ayrıca 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanunu da kişisel verilerin korunmasına ilişkin hüküm içermektedir. 5237 Sayılı Türk Ceza Kanununda ise, kişisel verilerin hukuka aykırı olarak, kaydedilmesi, ele geçirilmesi, yayılması ve saklama sürelerinin dolmasına rağmen sistem içerisinde silinmesi, yok edilmesi ya da anonim hale getirilmemesi halinde, cezai yaptırımlar öngörülmüştür. Diğer yandan, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği'nden doğan yükümlülüklerimizin ifası amacıyla verilerin toplanması ve kullanılması gerekmektedir.
        </p>

        <h4> 2- Liwa Yazılım kişisel verilerin toplanmasında hangi yöntemleri kullanıyor? </h4>
        <p>
        www.liwasoft.com  web sitesinden veya mobil uygulamalardan işlem yapan müşterilerimizin kişisel verileri, müşterilerimizin açık rızaları ve mevzuat hükümleri uyarınca Liwa Yazılım tarafından işlenmektedir. Kullanıcıların kişisel bilgileri, adı - soyadı, doğum tarihi, yüklediği dosyalardaki kişilerin bilgileri telefon numarası, e-posta adresi, T.C kimlik numarası gibi kullanıcıyı doğrudan ya da dolaylı olarak tanımlamaya yönelik her türlü kişisel bilgi olup, bu gizlilik politikasında "kişisel bilgiler" olarak anılacaktır.
        Bu Gizlilik Bildirimi, kişisel verilerinizin tarafımızca toplanması, kullanımı, paylaşımı, muhafaza edilmesi ve korunması konularını ve bunlar ile ilgili haklarınızı açıklamaktadır. Bu Gizlilik Bildirimi mobil cihazlar da dahil olmak üzere, erişim veya kullanım yönteminize bakılmaksızın, internet sitemiz de dahil olmak üzere, bu Gizlilik Bildirimine atıfta bulunulan her tür uygulama ve hizmet sunumuna (birlikte, "Hizmetler") uygulanacaktır. Hizmetlerimizi kullanarak ve/veya hesap oluşturarak bu Gizlilik Bildirimi ve Kullanıcı Sözleşmemizi kabul etmekte ve bu Gizlilik Bildirimi'nde açıklandığı üzere, kişisel verilerinizi toplamamıza, kullanmamıza, gerektiğinde üçüncü kişilerle paylaşmamıza, muhafaza etmemize ve korumamıza rıza göstermektesiniz.
        Liwa Yazılıma ait olan www.liwasoft.com  web sitesi çerez (cookie) kullanan bir sitedir. Çerez; kullanılmakta olan cihazın internet tarayıcısına ya da sabit diskine depolanarak söz konusu cihazın tespit edilmesine imkân tanıyan, çoğunlukla harf ve sayılardan oluşan bir dosyadır.
        www.liwasoft.com ziyaretçilerine daha iyi hizmet verebilmek amacıyla ve yasal yükümlülüğü çerçevesinde, işbu Kişisel Verilerin Korunması Hakkında Açıklama metninde belirlenen amaçlar ve kapsam dışında kullanılmamak kaydı ile gezinme bilgilerinizi toplayacak, işleyecek, üçüncü kişilerle paylaşacak ve güvenli olarak saklayacaktır.
        www.liwasoft.com çerezleri; günlük dosyaları, boş gif dosyaları ve/veya üçüncü taraf kaynakları yoluyla topladığı bilgileri tercihlerinizle ilgili bir özet oluşturmak amacıyla depolar. www.liwasoft.com size özel tanıtım yapmak, promosyonlar ve pazarlama teklifleri sunmak, web sitesinin veya mobil uygulamanın içeriğini size göre iyileştirmek ve/veya tercihlerinizi belirlemek amacıyla; site üzerinde gezinme bilgilerinizi ve/veya site üzerindeki kullanım geçmişinizi izleyebilmektedir.
        www.liwasoft.com çevrimiçi ve çevrimdışı olarak toplanan bilgiler gibi farklı yöntemlerle veya farklı zamanlarda site üzerinde sizden toplanan bilgileri eşleştirebilir ve bu bilgileri üçüncü taraflar gibi başka kaynaklardan alınan bilgilerle birlikte kullanabilir.
        www.liwasoft.com mobil uygulamasında oturum çerezleri ve kalıcı çerezler kullanmaktadır. Oturum kimliği çerezi, tarayıcınızı kapattığınızda sona erer. Kalıcı çerez ise sabit diskinizde uzun bir süre kalır. İnternet tarayıcınızın "yardım" dosyasında verilen talimatları izleyerek veya "www.allaboutcookies.org" veya "www.youronlinechoices.eu" adresini ziyaret ederek kalıcı çerezleri kaldırabilir ve hem oturum çerezlerini hem de kalıcı çerezleri reddedebilirsiniz. Kalıcı çerezleri veya oturum çerezlerini reddederseniz, web sitesini, mobil uygulamayı kullanmaya devam edebilirsiniz fakat web sitesinin, mobil uygulamanın tüm işlevlerine erişemeyebilirsiniz veya erişiminiz sınırlı olabilir.
        www.liwasoft.com, internet sayfasını kullanan kullanıcıların istatistiksel bilgileri ve yaptığı işlemler sistem tarafından kayıt altında tutulur. Kullanıcı, sistem kayıtlarındaki hareketlerinden sorumludur.
        www.liwasoft.com web sitesinde sunulan hizmetlerden yararlananlar bütün bu şartları okumuş ve kabul etmiş sayılırlar. Liwa Yazılım San. Tic. Ltd.  Şti. ait www.liwasoft.com, Gizlilik Politikası hükümlerini önceden haber vermeksizin değiştirme hakkını saklı tutar. Güncel Gizlilik Politikası, Kullanıcıya herhangi bir yöntemle sunulduğu tarihte yürürlük kazanır.
        </p>
        <h4>3- İnternet Sitesi Çerezleri Nasıl Kullanılmaktadır?</h4>
        <p>
        Liwa Yazılıma ait olan www.liwasoft.com web sitesi çerez (cookie) kullanan bir sitedir. Çerez; kullanılmakta olan cihazın internet tarayıcısına ya da sabit diskine depolanarak söz konusu cihazın tespit edilmesine olanak tanıyan, çoğunlukla harf ve sayılardan oluşan bir dosyadır.
        www.liwasoft.com çerezleri; günlük dosyaları, boş gif dosyaları ve/veya üçüncü taraf kaynakları yoluyla topladığı bilgileri tercihlerinizle ilgili bir özet oluşturmak amacıyla depolar.
        Oturum çerezleri (session cookies) ve kalıcı çerezler (persistent cookies) olmak üzere sitelerimiz genelinde iki tür çerez kullanmaktayız. Oturum çerezleri geçici çerezler olup sadece tarayıcınızı kapatıncaya kadar geçerlidirler. Kalıcı çerezler siz silinceye veya süreleri doluncaya (bu şekilde çerezlerin cihazında ne kadar kalacağı, çerezlerin "kullanım ömürlerine" bağlı olacaktır) kadar sabit diskinizde kalırlar.
        www.liwasoft.com çerezleri; yaptığınız tercihleri hatırlamak ve web sitesi/mobil uygulama kullanımınızı kişiselleştirmek için kullanır. Bu kullanım parolanızı kaydeden ve web sitesi/mobil uygulama oturumunuzun sürekli açık kalmasını sağlayan, böylece her ziyaretinizde birden fazla kez parola girme zahmetinden kurtaran çerezleri ve web sitesi/mobil uygulamaya daha sonraki ziyaretlerinizde sizi hatırlayan ve tanıyan çerezleri içerir.
        www.liwasoft.com web sitesine nereden bağlandığınız, web sitesi/mobil uygulama üzerinde hangi içeriği görüntülediğiniz ve ziyaretinizin süresi gibi web sitesini/mobil uygulamayı nasıl kullandığınızın ölçümlenmesi dahil olmak üzere web sitesini/mobil uygulamayı nasıl kullandığınızı tespit etmek için kullanır.
        www.liwasoft.com web sitesi çerezleri ayrıca; arama motorlarını, web sitesi, mobil uygulamasını ve/veya web sitesinin reklam verdiği internet sitelerini ziyaret ettiğinizde ilginizi çekebileceğini düşündüğü reklamları size sunabilmek için "reklam teknolojisini" devreye sokmak amacıyla kullanabilir. Reklam teknolojisi, size özel reklamlar sunabilmek için web sitesine/mobil uygulamaya ve web sitesinin reklam verdiği web sitelerine/mobil uygulamalarına yaptığınız önceki ziyaretlerle ilgili bilgileri kullanır. Bu reklamları sunarken, web sitesinin sizi tanıyabilmesi amacıyla tarayıcınıza benzersiz bir üçüncü taraf çerezi yerleştirilebilir. Liwa Yazılım ayrıca Google, Inc. tarafından sağlanan bir web analizi hizmeti olan Google Analytics kullanmaktadır. Google Analytics, çerezleri kullanıcıların web sitesini, mobil uygulamayı ve/veya mobil sitesini nasıl kullandıklarını istatistiki bilgiler/raporlar ile analiz etmek amacıyla kullanır. Google Analytics kullanımı hakkında daha fazla bilgi için (reddetme seçenekleri dahil), şu adresi ziyaret edebilirsiniz: http://www.google.com/intl/tr/policies/privacy/#infocollect
        Mobil uygulamada çerez yerine ilgili uygulamanın SDK'sı (Software Development Kit) kullanılmaktadır.
        Aşağıdaki yöntemleri kullanarak çerezlere izin verme ve reddetme imkanını kullanabilirsiniz:
        Google Chrome : Tarayıcınızın adres bölümünde yer alan, "kilit işareti"ni tıklayarak, "Çerezler" sekmesinden çerezlere izin verebilir veya engelleyebilirsiniz.
        Internet Explorer : Tarayıcınızın sağ üst bölümünde yer alan "Araçlar" bölümünden güvenlik sekmesini tıklayarak "izin ver" veya "izin verme" şeklinde çerezleri yönetebilirsiniz.
        Mozilla Firefox : Tarayıcınızın sağ üst köşesinde yer alan "menüyü aç" sekmesini tıklayınız. "Seçenekler" görselini tıklayarak "Gizlilik ve Güvenlik" butonunu kullanarak çerezleri yönetebilirsiniz.
        Opera : Tarayıcınızın "Tercihler" bölümünde "Gelişmiş"i seçerek "Çerezler" bölümünden çerez yönetimini yapabilirsiniz.
        Safari : Telefonunuzun "Ayarlar" bölümünden "safari" sekmesini seçip, "Gizlilik ve Güvenlik" Bölümünden tüm çerez yönetiminizi yapabilirsiniz.
        Yukarıdaki seçeneklerin yanı sıra; tüm çerezler hakkında bilgi sahibi olmak ve çerez yönetimi için: https://www.allaboutcookies.org, https://www.youronlinechoices.eu/ adresini ziyaret edebilirsiniz, veya "Privacy Badger" uygulamasını kullanabilirsiniz (https://www.eff.org/tr/privacybadger). Kalıcı çerezleri veya oturum çerezlerini reddederseniz, web sitesini, mobil uygulamayı ve mobil sitesini kullanmaya devam edebilirsiniz fakat web sitesinin, mobil uygulamanın ve mobil sitesinin tüm işlevlerine erişemeyebilirsiniz veya erişiminiz sınırlı olabilir.
        Liwa Yazılımda yer alan çerezlere ilişkin bilgiler aşağıdaki tablolarda yer almaktadır:
        </p>
        <table>
        <thead>
            <tr>
                <th>Service Provider</th>
                <th>Cookie İsmi</th>
                <th>Cookie Amacı</th>
                <th>Cookie Tipi</th>
                <th>Cookie Süresi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Google</td>
                <td>_gat</td>
                <td>Analytics cihaz bilgisi - Google Tag Manager sisteminde yer alan uygulamarın kullanması için</td>
                <td>Persistent Cookie</td>
                <td>2 Yıl</td>
            </tr>
            <tr>
                <td>Liwa Yazılım</td>
                <td>ASP.NET_SessionId</td>
                <td>Kullanıcıya ait session ID değerinin saklandığı cookiedir.</td>
                <td>Persistent Cookie</td>
                <td>30 Dakika</td>
            </tr>
        </tbody>
        </table>
        <h4>4- Liwa Yazılım kişisel verileri hangi amaçlarla kullanıyor?</h4>
        <p>Liwa Yazılım, mevzuatın izin verdiği durumlarda amacına uygun olarak ve ölçülü bir şekilde kişisel bilgilerinizi işleyebilecek, kaydedebilecek, güvenli bir biçimde saklayabilecek, güncelleyebilecek, üçüncü kişilere açıklayabilecek, devredebilecek, sınıflandırabilecektir.
        Liwa Yazılımda kişisel verileriniz şu amaçlarla kullanılmaktadır:
        "    Web sitesi/mobil uygulamalar üzerinden alışveriş yapanın/yaptıranın kimlik bilgilerini teyit etmek,
        "    İletişim için adres ve diğer gerekli bilgileri kaydetmek,
        "    Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun'un ilgili maddeleri tahtında akdettiğimiz sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili müşterilerimiz ile iletişime geçmek, gerekli bilgilendirmeleri yapabilmek,
        "    Elektronik (internet/mobil vs.) veya kâğıt ortamında işleme dayanak olacak tüm kayıt ve belgeleri düzenlemek,
        "    Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun'un ilgili maddeleri tahtında akdettiğimiz sözleşmeler uyarınca üstlenilen yükümlülükleri ifa etmek,
        "    Kamu güvenliğine ilişkin hususlarda talep halinde ve mevzuat gereği kamu görevlilerine bilgi verebilmek,
        "    Müşterilerimize daha iyi bir alışveriş deneyimini sağlamak, "müşterilerimizin ilgi alanlarını dikkate alarak" müşterilerimizin ilgilenebileceği ürünlerimiz hakkında müşterilerimize bilgi verebilmek, kampanyaları aktarmak,
        "    Müşteri memnuniyetini artırmak, web sitesi ve/veya mobil uygulamalardan alışveriş yapan müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde kullanabilmek, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmek ve bu kapsamda anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anketler düzenlemek,
        "    Anlaşmalı kurumlarımız ve çözüm ortaklarımız tarafından müşterilerimize öneri sunabilmek, hizmetlerimizle ilgili müşterilerimizi bilgilendirebilmek,
        "    Hizmetlerimiz ile ilgili müşteri şikâyet ve önerilerini değerlendirebilmek,
        "    Yasal yükümlülüklerimizi yerine getirebilmek ve yürürlükteki mevzuattan doğan haklarımızı kullanabilmek,
        </p>
        <h4>5- Liwa Yazılım kişisel verilerinizi nasıl koruyor?</h4>
        <p>Liwa Yazılım ile paylaşılan kişisel veriler, Liwa Yazılım gözetimi ve kontrolü altındadır. Liwa Yazılım, yürürlükteki ilgili mevzuat hükümleri gereğince bilginin gizliliğinin ve bütünlüğünün korunması amacıyla gerekli organizasyonu kurmak ve teknik önlemleri almak ve uyarlamak konusunda veri sorumlusu sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki yükümlülüğümüzün bilincinde olarak veri gizliliğini konu alan uluslararası ve ulusal teknik standartlara uygun surette periyodik aralıklarda sızma testleri yaptırılmakta ve bu kapsamda veri işleme politikalarımızı her zaman güncellediğimizi bilginize sunarız.</p>
        <h4>6- Liwa Yazılım kişisel verilerinizi paylaşıyor mu?</h4>
        <p>Müşterilerimize ait kişisel verilerin üçüncü kişiler ile paylaşımı, müşterilerin izni çerçevesinde gerçekleşmekte ve kural olarak müşterimizin onayı olmaksızın kişisel verileri üçüncü kişilerle paylaşılmamaktadır.
        Bununla birlikte, yasal yükümlülüklerimiz nedeniyle ve bunlarla sınırlı olmak üzere mahkemeler ve diğer kamu kurumları ile kişisel veriler paylaşılmaktadır. Ayrıca, taahhüt ettiğimiz hizmetleri sağlayabilmek ve verilen hizmetlerin kalite kontrolünü yapabilmek için anlaşmalı üçüncü kişilere kişisel veri aktarımı yapılmaktadır.
        Üçüncü kişilere veri aktarımı sırasında hak ihlallerini önlemek için gerekli teknik ve hukuki önlemler alınmaktadır. Bununla birlikte, kişisel verileri alan üçüncü kişinin veri koruma politikalarından dolayı ve üçüncü kişinin sorumluluğundaki risk alanında meydana gelen ihlallerden Liwa Yazılım sorumlu değildir.
        Kişisel verileriniz Liwa Yazılımın hissedarlarıyla, doğrudan/dolaylı yurtiçi/yurtdışı faaliyetlerimizi yürütebilmek için işbirliği yaptığımız program ortağı kurum, kuruluşlarla, verilerin bulut ortamında saklanması hizmeti aldığımız yurtiçi/yurtdışı kişi ve kurumlarla, müşterilerimize ticari elektronik iletilerin gönderilmesi konusunda anlaşmalı olduğumuz yurtiçi/yurtdışındaki kuruluşlarla, Bankalar arası Kart Merkeziyle, anlaşmalı olduğumuz bankalarla ve sizlere daha iyi hizmet sunabilmek ve müşteri memnuniyetini sağlayabilmek için çeşitli pazarlama faaliyetleri kapsamında yurtiçi ve yurtdışındaki çeşitli ajans, reklam şirketleri ve anket şirketleriyle ve yurtiçi/yurtdışı diğer üçüncü kişilerle ve ilgili iş ortaklarımızla paylaşılabilmektedir.</p>
      <h4>7- Kişisel Verilerin Korunması Kanunu'ndan doğan haklarınız nelerdir?</h4>    
      <p>6698 sayılı Kanun uyarınca kişisel verilerinizin;
      <ul>
        <li>a. İşlenip işlenmediğini öğrenme,</li>
        <li>b. İşlenmişse bilgi talep etme,</li>
        <li>c. İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
        <li>d. Yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme,</li>
        <li>e. Eksik / yanlış işlenmişse düzeltilmesini isteme,</li>
        <li>f. 6698 sayılı Kanun'un 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme,</li>
        <li>g. Aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme,</li>
        <li>h. Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
        <li>i. 6698 sayılı Kişisel Verilerin Korunması Kanunu'na aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme haklarına sahip olduğunuzu hatırlatmak isteriz,</li>
        <li>j. Başvuru formu.</li>
      </ul>
      </p>
      <h4>8- Kişisel verilerle ilgili mevzuat değişikliklerinden nasıl haberdar olabilirim?</h4>
      <p>
      6698 sayılı Kanun uyarınca, sahip olduğunuz haklar Liwa Yazılım yükümlülükleridir. Kişisel verilerinizi bu bilinçle ve mevzuatın gerektirdiği ölçüde işlediğimizi, yasal değişikliklerin olması halinde sayfamızda yer alan bu bilgileri yeni mevzuata uygun güncelleyeceğimizi, yapılan güncellemeleri de bu sayfa üzerinden her zaman kolaylıkla takip edebileceğinizi size bildirmek isteriz.
      </p>
    <h4>9- Verinin güncel ve doğru tutulduğundan nasıl emin olabilirim?</h4>
    <p>6698 sayılı Kanun'un 4. maddesi uyarınca Liwa Yazılımın kişisel verilerinizi doğru ve güncel olarak tutma yükümlülüğü bulunmaktadır. Bu kapsamda Liwa Yazılımın yürürlükteki mevzuattan doğan yükümlülüklerini yerine getirebilmesi için müşterilerimizin Liwa Yazılım doğru ve güncel verilerini paylaşması gerekmektedir. Verilerinizin herhangi bir surette değişikliğe uğraması halinde aşağıda belirtilen iletişim kanallarından bizimle iletişime geçerek verilerinizi güncellemenizi rica ederiz.</p>
    <h4>10- Liwa Yazılıma kişisel verilerinizle ilgili soru sormak ister misiniz?</h4>
    <p>Kişisel verilerinizle ilgili her türlü soru ve görüşleriniz için support@liwasoft.com posta adresinden dilediğiniz zaman bize ulaşabilirsiniz.</p>
  </div>
  <Footer/>
</div>
  )
}

export default Privacy