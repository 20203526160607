import logo from "../../img/logo.png";
import { useTranslation } from "react-i18next";
import "./footer.css";

function Footer() {
  const { t } = useTranslation("translate");

  return (
    <>
      {/* ------ Footer section ------- */}
      <section id="footer_contactus" className="start_animate">
        <div className="footer-cont">
          <div className="footer-links">
            <div className="footer-1 link-cont">
              <div className="logo">
                <img src={logo} alt="Logo" />
                <p>Liwa Soft</p>
              </div>

              <div className="phone_div">
                <a href="tel:0850 840 78 07">
                  <p className="footerPhone"> 📱 0850 840 78 07</p>
                </a>
                <a href="tel:0533 969 57 61">
                  <p className="footerPhone"> 📱 0533 969 57 61</p>
                </a>
              </div>
            </div>
          </div>

          <div className="footer-copy">
            <p>{t("footer.copy")}</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
