import React, { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import doner from '../../img/logos/46-doner.png';
import cafe from '../../img/logos/cafe.png';
import dulka from '../../img/logos/dulka.png';
import enfes from '../../img/logos/enfes.png';
import gumus from '../../img/logos/gumus.png';
import karsu from '../../img/logos/karsu.png';
import medical from '../../img/logos/medical.png';
import orkis from '../../img/logos/orkis.png';
import parca from '../../img/logos/parca.png';
import saray from '../../img/logos/saray.png';
import sd_doner from '../../img/logos/sd-doner.png';

function SliderFunc() {
  const logos = [sd_doner, karsu, medical, doner, parca, enfes, orkis, saray, gumus, cafe, dulka];

  const [settings] = useState({
    dots: false,
    infinite: true,
    /* slidesToShow: 6, */
    variableWidth: true,
    /* slidesToScroll: 1, */
    autoplay: true,
    autoplaySpeed: 1,
    speed: 2000,
    cssEase: 'linear',
    direction: 'ltr',
    pauseOnHover: false,
    arrows: false,
    
  });

  return (
    <div>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div className='container' key={index}>
            <img src={logo} alt={`logo-${index}`} style={{ width: 'auto', height: '5rem', marginLeft: "3rem" }} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SliderFunc;
