import React, { useContext, useEffect, useState } from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import ContactForm from '../form/ContactForm';
import Card from '../card/Card';
import './cart.css'
import { Mycontext } from '../../contexts/Context_1';
import { useTranslation } from 'react-i18next';
import Popup from '../popup/Popup';
import { PopUp } from '../../contexts/PopContext';

function Cart() {
  const localPrices = JSON.parse(localStorage.getItem("prices"));
  const { t } = useTranslation("translate");
  const [subTot, setSubTot] = useState(0);
  const [KDV, setKDV] = useState(0);
  const [totalPrice, SetTotalPrice] = useState(0);
  // Contexts
  const { contactStat, handleContactStat, TRY, tempPrices, setPrices } = useContext(Mycontext);
  const { handlepopUp } = useContext(PopUp);

  //TRY && console.log("TRY","TRY", TRY);

  useEffect(() => {
    if (TRY) {
      let locsubtot = 0;
      let lockdv = 0;
      let loctot = 0;

      if (localPrices) {
        localPrices.forEach((item) => {
          if (item.amount > 0) {
            let amount = item.amount;
            locsubtot += item.price * amount * TRY;
            lockdv += item.kdv * amount * TRY;
            loctot += item.total * amount * TRY;
          }
        });

        setSubTot(parseFloat(locsubtot.toFixed(2)).toLocaleString('tr-TR'));
        setKDV(parseFloat(lockdv.toFixed(2)).toLocaleString('tr-TR'));
        SetTotalPrice(parseFloat(loctot.toFixed(2)).toLocaleString('tr-TR'));
        //console.log("TRY", TRY);
      }
    }
  }, [TRY, localPrices]);

const emptyTheCart = () => {
  tempPrices.forEach((item) => {
    item.amount = 0;
  })
  localStorage.setItem("prices", JSON.stringify(tempPrices));
  setPrices(JSON.parse(localStorage.getItem("prices")));
  handlepopUp("removed")
}

  return (
    <div>
      <Header/>
      <Popup/>
      <div className={`form_cont ${contactStat ? "active": ""}`}>
        <ContactForm className={"isCart"}/>
      </div>
      <section id='Cart'>
        <div className="cart_holder">
          <div className="title"><h1>{t("cart.title")}<span></span></h1></div>

            <div className="top_cont">
              <div className="btn-cont">
                {localPrices && localPrices.some((item) => item.amount !== 0) && (<button className='btn' onClick={emptyTheCart}>{t("cart.empty")}</button>)}
                <button className='btn empty_btn' onClick={handleContactStat}>{t("cart.btn")}</button>
              </div>
            </div>

          <div className="carts_cont">

            <div className="left_cont">
              {localPrices && localPrices.map((item, i)=> (
                <Card src={item.src} title={item.title} price={item.price} kdv={item.kdv} total={item.total} amount={item.amount} i={i+1} key={item.title}/>
              ))}
            </div>

            <div className="right_cont">

              <div className="info_cont">
                <div className="left_info">
                  {/* <div className=''></div> */}
                  <div>
                    <p>{t("cart.name")}</p>
                    <p>{t("cart.currency")}</p>
                    <p>IBAN</p>
                    <p>Hesap Adı</p>
                  </div>
                  <div>
                    <p>Garanti Bankası</p>
                    <p>TL</p>
                    <p>TR50 0006 2001 5260 0006 2978 70</p>
                    <p>Liwa Yazılım San. Tic. Ltd. Şti.</p>
                  </div>
                </div>

                <div className="right_info">
                  <div>
                   {/*  <p>{t("cart.place")}</p> */}
                    <p>{t("cart.sub")}</p>
                    <p>KDV</p> 
                    <p>{t("cart.tot")}</p>
                    <p>Güncel Kur $</p>
                  </div>

                  <div>
                    {/* <p>Kurtuluş / Kahramanmaraş</p> */}
                    <p>{subTot} TL</p>
                    <p>{KDV} TL</p>
                    <p>{totalPrice} TL</p>
                    <p>{TRY} TL</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  )
}

export default Cart;