import React, { useContext } from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Promo from '../promo/Promo';
import './products.css'

import prod_1 from "../../img/Products/prod-1.png" //Getir Yemek 
import prod_2 from "../../img/Products/prod-2.png" //Yemek Sepeti
import prod_3 from "../../img/Products/prod-9.png" //TRENDYOL YEMEK
import prod_4 from "../../img/Products/prod-5.png" //SAMBA PATRON
import prod_5 from "../../img/Products/prod-6.png" //SAMBA GARSON
import prod_6 from "../../GIF/mobile caller id.gif" //MOBİLE CALLER ID
import prod_7 from "../../img/Products/prod-8.png" //VERİTABANI
import prod_8 from "../../img/Products/prod-4.png" //VOIP CALLER
import prod_9 from "../../img/Products/prod-3.png" //Akıllı Garson
import prod_10 from "../../img/Products/prod-10.png" //E-Adisyon
import prod_11 from "../../img/Products/prod-11.png" //Pentegrasyon

import YT from "../../img/YT.png"
import { useTranslation } from 'react-i18next';
import { Mycontext } from '../../contexts/Context_1';
import Popup from '../popup/Popup';
import { Link } from 'react-router-dom';

<iframe width="560" height="315" src="https://www.youtube.com/embed/oOUYjVROp3c?si=9YjJnKirozRq1Yb_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

function Products() {
  const src_1 = "https://www.youtube.com/embed/P_UzVlK0_os?si=LqesVgoiNM43aHYe"; ////Getir Yemek
  const src_2 = "https://www.youtube.com/embed/wYF2TpnxNCc?si=fQjhCGsWMbnKCDxH"; ////Yemek Sepeti
  const src_3 = "https://www.youtube.com/embed/P_UzVlK0_os?si=noL5g5L1iGF7Au0t"; ////TRENDYOL YEMEK
  const src_4 = "https://www.youtube.com/embed/6gazCO-Q2sM?si=8YxBQ4yH0D7ewJXN"; ////SAMBA PATRON
  const src_5 = "https://www.youtube.com/embed/YoPYxrWO6xk?si=HvH0xLTKC4XgM9q8"; ////SAMBA GARSON
  const src_6 = ""; ////MOBİLE CALLER ID
  const src_7 = "https://www.youtube.com/embed/nz0erc6m5Wo?si=6DsBbOQfrFKrXdMm"; ////VERİTABANI
  const src_8 = "https://www.youtube.com/embed/oOUYjVROp3c?si=9YjJnKirozRq1Yb_"; ////VOIP CALLER
  const src_9 = "https://www.youtube.com/embed/DgWWQkpohuQ?si=xBUUGpcO7uYYBDkV"; ////Akıllı Garson
  const src_10 = "https://www.youtube.com/embed/-1vA4l1Zu2Q?si=lEew0Z9HBmcDwoRg"; ////E-Adisyon
  const src_11 = "https://www.youtube.com/embed/Uf2sYBCR9AI?si=0aXP0usKfbk0t8lQ"; ////Pentegrasyon

  /* Play Store */
  const pls_1 = "" //Getir Yemek 
  const pls_2 = "" //Yemek Sepeti
  const pls_3 = "" //TRENDYOL YEMEK
  const pls_4 = "https://play.google.com/store/apps/details?id=com.devearth.sambapatron" //SAMBA PATRON
  const pls_5 = "https://play.google.com/store/apps/details?id=com.devearth.sambagarson&gl=TR" //SAMBA GARSON
  const pls_6 = "" //MOBİLE CALLER ID
  const pls_7 = "" //VERİTABANI
  const pls_8 = "" //VOIP CALLER
  const pls_9 = "https://play.google.com/store/apps/details?id=com.devearth.akilligarson" //Akıllı Garson
  const pls_10 = "" //E-Adisyon
  const pls_11 = "" //Pentegrasyon

  /* Apple Store */
  const aps_1 = "" //Getir Yemek 
  const aps_2 = "" //Yemek Sepeti
  const aps_3 = "" //TRENDYOL YEMEK
  const aps_4 = "https://apps.apple.com/tr/app/samba-patron-samba-boss/id1598881147" //SAMBA PATRON
  const aps_5 = "" //SAMBA GARSON
  const aps_6 = "" //MOBİLE CALLER ID
  const aps_7 = "" //VERİTABANI
  const aps_8 = "" //VOIP CALLER
  const aps_9 = "" //Akıllı Garson
  const aps_10 = "" //E-Adisyon
  const aps_11 = "" //Pentegrasyon

  const { t } = useTranslation("translate");
  const { handlePrices, handlePromo } = useContext(Mycontext);
  const { tempPrices } = useContext(Mycontext);
  const prods = [ 
    {prod: prod_1, src: src_1, pls: pls_1, aps: aps_1, price: tempPrices[0].price}, 
    {prod: prod_2, src: src_2, pls: pls_2, aps: aps_2, price: tempPrices[1].price}, 
    {prod: prod_3, src: src_3, pls: pls_3, aps: aps_3, price: tempPrices[2].price}, 
    {prod: prod_4, src: src_4, pls: pls_4, aps: aps_4, price: tempPrices[3].price}, 
    {prod: prod_5, src: src_5, pls: pls_5, aps: aps_5, price: tempPrices[4].price}, 
    {prod: prod_6, src: src_6, pls: pls_6, aps: aps_6, price: tempPrices[5].price}, 
    {prod: prod_7, src: src_7, pls: pls_7, aps: aps_7, price: tempPrices[6].price}, 
    {prod: prod_8, src: src_8, pls: pls_8, aps: aps_8, price: tempPrices[7].price}, 
    {prod: prod_9, src: src_9, pls: pls_9, aps: aps_9, price: tempPrices[8].price},
    {prod: prod_10, src: src_10, pls: pls_10, aps: aps_10, price: tempPrices[9].price},
    {prod: prod_11, src: src_11, pls: pls_11, aps: aps_11, price: tempPrices[10].price}
  ];
  

  return (
    <div>
      <Header/>
      <Popup/>
        <section id='Products'>
          <Promo/>
          <div className="Products-container">
              <div className="title"><h1>{t("header.products")} <span></span></h1></div>
              {prods && prods.map((prod, i) => (
               <div className={`Prod_cart Prod_${i+1}`} key={`prod_${i}`}>
                 <div className='img-bg'>
                 <div className="img-cont">
                    <img src={prod.prod} alt={`Prod_${i+1}`} />
                    <div className="yt_cont"> 
                      {prod.src ? (<img src={YT} alt="YT" onClick={()=> handlePromo(prod.src)}/>): null}
                    </div>
                 </div>
                 </div>

                 <div className="subtexts">
                     <h2>{t(`Product.prod-${i+1}.title`)}<br/>
                       <span className='subcolor'>{prod.price} {t(`Product.prod-${i+1}.span`)}</span>
                     </h2>
                     <p>{t(`Product.prod-${i+1}.txt`)}</p>
                   <div className="btn-cont">
                    {prod.pls ? (<a href={prod.pls} className="btn">Playstore</a>) : null}
                    {prod.aps ? (<a href={prod.aps} className="btn">App Store</a>) : null}
                     <Link onClick={() => handlePrices(`prod-${i+1}`, 1)} className="btn">{t("Product.prod-1.a-1")}</Link>
                   </div>
                 </div>
               </div>
              ))}
          </div>
        </section>
      <Footer/>
    </div>
  )
}

export default Products;